import { Button, Col, Drawer, Pagination, Row, Spin } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyProfile from '~/components/shared-components/BodyProfile'
import { useHash } from '~/views/redux/hooks/useHash'
import configs from '~/configs'
import { useQueryState } from '~/common/hooks/useQueryState'
import { NodataMonster } from '~/components/shared-components/NoData/NodataMonster'
import SellModal from '~/components/Modals/SellModal'
import OpenBox from '~/components/Modals/Open'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import OpenSuccess from '~/components/Modals/OpenSuccess'
import { useSDK } from '@metamask/sdk-react'
import {
  GENESIS_SORT_SALE_USER,
  SCREEN_SIZE,
  converImageGeneralBox,
  converImageGenesisBox,
  convertImageMonsterOther,
} from '~/common/constants'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  toWei,
} from '~/blockchain/provider'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import {
  getContractHash,
  ifaceContractMarketplace,
  resultOpenGeneral,
  resultOpenGenesis,
} from '~/blockchain/contract'
import { BoxCardHash } from '~/components/shared-components/Card/BoxCardHash'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import { getSpeciesValue } from '~/helpers/Species'
import { getNameItem, getNameItemByContract } from '~/helpers/ItemName'
import SellToMKPModal from '~/components/Modals/SellToMKPModal'
import { scrollToTop } from '~/helpers/common'
import { useMediaQuery } from 'react-responsive'
import { FilterOutlined } from '@ant-design/icons'
import getImageHash from '~/assets/data/ImageHash.json'
import { FilterHashNFTMyAsset } from '~/views/app/HashNFT/components/FilterHashNFTMyAsset'
export const AssetHash = () => {
  const { sdk } = useSDK()
  const [mainSeedFilter, setMainSeedFilter] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.mainSeedFilter || [],
  )

  const [category, setCategory] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.category || [],
  )
  const [regenerationCount, setRegenerationCount] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.regenerationCount || [
      0, 5,
    ],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.page || 1,
  )

  const [typeSort, setTypeSort] = useState('-1')
  const [isModalSell, setIsModalSell] = useState(false)
  const [isModalRegenSell, setIsModalRegenSell] = useState(false)
  const [nftActive, setNftActive] = useState()
  const [isLoadingModal, setLoadingModal] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [txHash, setTxHash] = useState()
  const groups = ['GROUP A', 'GROUP B', 'GROUP C', 'GROUP D', 'GROUP E']
  const [sort, setSort] = useState(GENESIS_SORT_SALE_USER[0])
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterHash, setOpenFilterHash] = useState(false)
  const {
    actions,
    data: {
      allHashUser,
      totalHashUser,
      totalBoxUser,
      totalCountUser,
      isLoading,
    },
  } = useHash()
  const {
    data: { user },
  } = useTheme()
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    category,
    mainSeedFilter,
    regenerationCount,
  ])
  // const { page, setPage } = useQueryState()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalSuccessSell, setIsModalSuccessOpenSell] = useState(false)
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false)
  const [dataResultOpen, setDataResultOpen] = useState()

  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      wallet_address: user.address_wallet,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      category: category?.map((data) => data?.name),
      main_seed: mainSeedFilter,
      regenerationCount: {
        from: regenerationCount[0],
        to: regenerationCount[1],
      },
    }
    actions.getAllHashUser(params, () => {
      scrollToTop()
    })
  }, [page, pageSize, category, mainSeedFilter, regenerationCount])

  useEffect(() => {
    if (user) {
      refreshData()
      loadBalanceOAS()
    }
  }, [
    dataResultOpen,
    page,
    pageSize,
    category,
    mainSeedFilter,
    regenerationCount,
    txHash,
  ])
  const handleCancelModalOpen = () => {
    setIsModalOpen(false)
    setLoadingModal(false)
  }
  const pageFilter = JSON.parse(localStorage.getItem('hashNFTMyAssets'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])
  const onChangePage = (page) => {
    setPage(page)
  }
  const showModalSuccessSell = () => {
    setIsModalSuccessOpenSell(true)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }
  const showModalSuccessOpen = () => {
    setIsModalSuccessOpen(true)
  }

  const [dataGenesis, setDataGenesis] = useState([])
  const handleOpen = async (data) => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()
        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(nftActive?.contractAddress)
        let param = {
          from: providerOAS.accounts[0],
          data:
            nftActive?.type_name === 'Genesis Box'
              ? iface.encodeFunctionData('openGenesisBox', [
                  groups.indexOf(data.type),
                ])
              : iface.encodeFunctionData('openGeneralBox', [
                  groups.indexOf(data.type),
                ]),
          to: addressContract,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [param],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then(async (r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            let result =
              nftActive?.type_name === 'Genesis Box'
                ? await resultOpenGenesis(transaction?.hash)
                : await resultOpenGeneral(transaction?.hash)
            setDataResultOpen(result)
            setLoadingModal(false)
            handleCancelModalOpen()
            showModalSuccessOpen()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        const nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })

        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(nftActive?.contractAddress)
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data:
                  nftActive?.type_name === 'Genesis Box'
                    ? iface.encodeFunctionData('openGenesisBox', [
                        groups.indexOf(data.type),
                      ])
                    : iface.encodeFunctionData('openGeneralBox', [
                        groups.indexOf(data.type),
                      ]),
                to: addressContract,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then(async (r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                let result =
                  nftActive?.type_name === 'Genesis Box'
                    ? await resultOpenGenesis(txHash)
                    : await resultOpenGeneral(txHash)
                setDataResultOpen(result)
                setLoadingModal(false)
                handleCancelModalOpen()
                showModalSuccessOpen()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }
  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }
  const handleSell = async (tokenID, price, quantity = 1) => {
    try {
      setLoadingModal(true)
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(nftActive?.contractAddress)

        let checkAllow
        if (nftActive?.type === 'Regeneration_Hash') {
          checkAllow = await contract.isApprovedForAll(
            user.address_wallet,
            configs.ADDRESS_MARKETPLACE,
          )
        } else {
          let allowanceHash = await contract.getApproved(tokenID)
          checkAllow =
            allowanceHash.toLowerCase() ===
            configs.ADDRESS_MARKETPLACE.toLowerCase()
        }
        if (checkAllow) {
          sell(tokenID, price, quantity, addressContract)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data:
              nftActive?.type === 'Regeneration_Hash'
                ? iface.encodeFunctionData('setApprovalForAll', [
                    configs.ADDRESS_MARKETPLACE,
                    true,
                  ])
                : iface.encodeFunctionData('approve', [
                    configs.ADDRESS_MARKETPLACE,
                    tokenID,
                  ]),
            to: addressContract,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(tokenID, price, quantity, addressContract)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return

        const {
          contract,
          iface,
          address: addressContract,
        } = getContractHash(nftActive?.contractAddress)
        // eslint-disable-next-line
        console.log(contract)

        let checkAllow
        if (nftActive?.type === 'Regeneration_Hash') {
          checkAllow = await contract.isApprovedForAll(
            user.address_wallet,
            configs.ADDRESS_MARKETPLACE,
          )
        } else {
          let allowanceHash = await contract.getApproved(tokenID)
          checkAllow =
            allowanceHash.toLowerCase() ===
            configs.ADDRESS_MARKETPLACE.toLowerCase()
        }

        if (checkAllow) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data:
                    nftActive?.type === 'Regeneration_Hash'
                      ? iface.encodeFunctionData('setApprovalForAll', [
                          configs.ADDRESS_MARKETPLACE,
                          true,
                        ])
                      : iface.encodeFunctionData('approve', [
                          configs.ADDRESS_MARKETPLACE,
                          tokenID,
                        ]),
                  to: addressContract,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(addressContract, tokenID, price, quantity)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }
  const sell = async (addressContract, tokenID, price, quantity) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [addressContract, tokenID, toWei(price), quantity],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            setTxHash(transaction?.hash)
            setLoadingModal(false)
            handleCancelModalSell()
            showModalSuccessSell()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [addressContract, tokenID, toWei(price), quantity],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                setTxHash(txHash)
                setLoadingModal(false)
                handleCancelModalSell()
                handleCancelModalSellRegen()
                showModalSuccessSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      setLoadingModal(false)
    }
  }
  const handleCancelSuccessOpen = () => {
    setIsModalSuccessOpen(false)
  }
  const handleShare = () => {
    setIsModalSuccessOpen(false)
  }
  const handleCancelSuccessSell = () => {
    setIsModalSuccessOpenSell(false)
  }
  const handleCancelModalSell = () => {
    setIsModalSell(false)
    setLoadingModal(false)
  }

  const handleCancelModalSellRegen = () => {
    setIsModalRegenSell(false)
    setLoadingModal(false)
  }
  const showModalSellRegen = () => {
    setIsModalRegenSell(true)
  }
  const showModalOpen = () => {
    setIsModalOpen(true)
  }
  const showModalSell = () => {
    setIsModalSell(true)
  }
  const showDrawer = () => {
    setOpenFilterHash(true)
  }

  const onClose = () => {
    setOpenFilterHash(false)
  }
  const clearAll = () => {
    setMainSeedFilter([])
    setCategory([])
    setRegenerationCount([])
  }
  const { t } = useTranslation()

  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      // eslint-disable-next-line
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      // eslint-disable-next-line
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      // eslint-disable-next-line
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      // eslint-disable-next-line
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      // eslint-disable-next-line
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      // eslint-disable-next-line
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      // eslint-disable-next-line
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      // eslint-disable-next-line
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }

  return (
    <BodyProfile>
      <Row className={`${isMobile ? '' : 'mx-auto h-[100vh]'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <div className="flex w-full h-full">
              <FilterHashNFTMyAsset
                mainSeedFilter={mainSeedFilter}
                category={category}
                regenerationCount={regenerationCount}
                setMainSeedFilter={setMainSeedFilter}
                setCategory={setCategory}
                setRegenerationCount={setRegenerationCount}
                page={page}
                pageSize={pageSize}
                classCustom="filter__profile"
              />
            </div>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="mx-auto z-10 pl-0 sm:pl-[15px] relative top-0 sm:pt-[1.5rem] pb-12">
            <div className="text-[24px] mb-4 uppercase">
              {t('profile.menu.hash')}
            </div>
            <div className="flex justify-between flex-wrap mb-4">
              <div className="text-[24px]">
                {t('countBox', {
                  total: totalBoxUser,
                })}
                {totalBoxUser > 1 ? t('es') : ''}
                <span className="mx-2">|</span>
                {t('countHash', {
                  total: totalHashUser,
                })}
                {totalHashUser > 1 ? t('more') : ''}
              </div>
              {isMobile ? (
                <div className="flex items-center text-[16px] gap-2">
                  <div>
                    <Button
                      type="primary"
                      onClick={showDrawer}
                      className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                    >
                      <FilterOutlined />
                      {t('filter')}
                    </Button>
                    <Drawer
                      title={t('filter')}
                      width={330}
                      placement="left"
                      onClose={onClose}
                      open={openFilterHash}
                      extra={
                        <div
                          className="text-[16px] text-[#FAAD14] cursor-pointer"
                          onClick={() => {
                            clearAll()
                          }}
                        >
                          {t('clearAll')}
                        </div>
                      }
                    >
                      <div className="flex w-full h-full">
                        <FilterHashNFTMyAsset
                          mainSeedFilter={mainSeedFilter}
                          category={category}
                          regenerationCount={regenerationCount}
                          setMainSeedFilter={setMainSeedFilter}
                          setCategory={setCategory}
                          setRegenerationCount={setRegenerationCount}
                          page={page}
                          pageSize={pageSize}
                          classCustom="filter__profile"
                        />
                      </div>
                    </Drawer>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <Spin spinning={isLoading}>
            <div className="flex flex-wrap justify-center gap-4">
              {/* Box */}
              {allHashUser?.map((data, i) => {
                let image
                switch (data?.asset) {
                  case 'GENESIS BOX':
                    image = `../imgs/box/${converImageGenesisBox(
                      data?.type.split(' ')[1],
                    )}.png`
                    break
                  case 'GENERAL BOX':
                    image = `../imgs/box/${converImageGeneralBox(
                      data?.type.split(' ')[1],
                    )}.png`
                    break
                  case 'GENESIS HASH':
                  case 'GENERAL HASH':
                    image = getSpeciesValue(data?.group, data?.species)?.image
                      ? `${
                          process.env.REACT_APP_BASE_URL_IMAGE
                        }/public/image/image/hash/${
                          getSpeciesValue(data?.group, data?.species)?.image
                        }.png`
                      : '../imgs/hash/golem.png'
                    break
                  case 'HASH CHIP NFT':
                    image = `../imgs/common/result.png`
                    break
                  default:
                    image =
                      data?.type === 'Regeneration_Hash' &&
                      getNameItem(3, data?.nft_id)?.image
                        ? `${
                            process.env.REACT_APP_BASE_URL_IMAGE
                          }/public/image/image/items/${
                            getNameItem(3, data?.nft_id)?.image
                          }.png`
                        : `${
                            process.env.REACT_APP_BASE_URL_IMAGE
                          }/public/image/image/hash/${getItemGraphic(
                            data?.name,
                          )}`
                    break
                }
                return (
                  <BoxCardHash
                    t={t}
                    key={i?.toString()}
                    data={data}
                    img={image}
                    button={{
                      title:
                        data?.asset === 'GENESIS BOX' ||
                        data?.asset === 'GENERAL BOX'
                          ? 'Open'
                          : 'Sell',

                      functionButton: () => {
                        if (
                          data?.asset === 'GENESIS BOX' ||
                          data?.asset === 'GENERAL BOX'
                        ) {
                          setNftActive(data)
                          showModalOpen()
                        } else {
                          setNftActive(data)
                          if (data?.type === 'Regeneration_Hash') {
                            showModalSellRegen()
                          } else {
                            showModalSell()
                          }
                        }
                      },
                    }}
                  />
                )
              })}
            </div>
          </Spin>
          {!isLoading && totalCountUser === 0 && <NodataMonster />}

          {totalCountUser > 0 && (
            <div className="pagination__common">
              <Pagination
                onChange={onChangePage}
                onShowSizeChange={onChangePageSize}
                className="mt-5"
                size="small"
                locale={{ jump_to: 'Go to', page: '' }}
                total={totalCountUser}
                // defaultCurrent={page}
                current={page}
                pageSize={pageSize}
                showSizeChanger
                showQuickJumper
                showTitle
              />
            </div>
          )}
          <SellToMKPModal
            action={{
              isModalOpen: isModalRegenSell,
              handleOk: handleSell,
              handleCancel: handleCancelModalSellRegen,
            }}
            tokenID={nftActive?.nft_id}
            t={t}
            loadingModal={isLoadingModal}
            // name={
            //   nftActive?.type_name === 'Genesis Hash' ||
            //   nftActive?.type_name === 'General Hash'
            //     ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            //     : getNameItemByContract(
            //         nftActive?.contractAddress,
            //         nftActive?.nft_id,
            //       )?.name
            // }
            name={nftActive?.name}
            // tema={tema}
            balance={balanceOAS}
            quanlityTotal={nftActive?.quantity}
          />
          <SellModal
            action={{
              isModalOpen: isModalSell,
              handleOk: handleSell,
              handleCancel: handleCancelModalSell,
            }}
            t={t}
            tokenID={nftActive?.tokenId ?? nftActive?.nft_id}
            loadingModal={isLoadingModal}
            // name={
            //   nftActive?.type_name === 'Genesis Hash' ||
            //   nftActive?.type_name === 'General Hash'
            //     ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            //     : getNameItemByContract(
            //         nftActive?.contractAddress,
            //         nftActive?.nft_id,
            //       )?.name
            // }
            name={nftActive?.name}
            // tema={tema}
            balance={balanceOAS}
          />
          <OpenBox
            key={dataGenesis?.type}
            action={{
              isModalOpen: isModalOpen,
              handleOk: handleOpen,
              handleCancel: handleCancelModalOpen,
            }}
            data={nftActive}
            t={t}
            loadingModal={isLoadingModal}
          />
          <TransactionCompletedSell
            action={{
              isModalOpen: isModalSuccessSell,
              handleOk: handleShare,
              handleCancel: handleCancelSuccessSell,
            }}
            t={t}
            // name={
            //   nftActive?.type_name === 'Genesis Hash' ||
            //   nftActive?.type_name === 'General Hash'
            //     ? `${nftActive?.main_seed} (#${nftActive?.tokenId})`
            //     : getNameItemByContract(
            //         nftActive?.contractAddress,
            //         nftActive?.nft_id,
            //       )?.name
            // }
            name={nftActive?.name}
            store={'Marketplace'}
            txHash={txHash}
          />
          <OpenSuccess
            key={dataGenesis?.type}
            action={{
              isModalOpen: isModalSuccessOpen,
              handleOk: handleShare,
              handleCancel: handleCancelSuccessOpen,
            }}
            data={{ ...nftActive, ...dataResultOpen }}
            t={t}
            loadingModal={isLoadingModal}
          />
        </Col>
      </Row>
    </BodyProfile>
  )
}
export default AssetHash
